import { initializeApp } from "firebase/app";

import { getFirestore } from "firebase/firestore";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDzlQIQggxamxQvw6hWkOmkG199h_-1lgY",
  authDomain: "myydoctor-cb5da.firebaseapp.com",
  databaseURL: "https://myydoctor-cb5da-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "myydoctor-cb5da",
  storageBucket: "myydoctor-cb5da.appspot.com",
  messagingSenderId: "798205149245",
  appId: "1:798205149245:web:81ff6ee9529b691c0b2a54",
  measurementId: "G-S7ZN1L3J3T"
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
const analytics = getAnalytics(app);

export const sendVerificationEmail = () => {
  const user = auth.currentUser;
  console.log(user, 'Current User')
  if (user) {
    sendEmailVerification(user)
      .then(() => {
        // Email sent successfully
        console.log('Verification email sent!');
      })
      .catch((error) => {
        // An error occurred
        console.error('Error sending verification email:', error);
      });
  }
};

export { firestore, auth, storage, analytics };
