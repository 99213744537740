import React, { useContext, useEffect, useRef, useState } from "react";

// framer motin
import { motion } from "framer-motion";

import { LazyLoadImage } from "react-lazy-load-image-component";
import { useParams } from "react-router-dom";

// firebase

import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  setDoc,
  where,
  orderBy,
  limit,
  getDocs,
} from "firebase/firestore";
import { firestore, storage } from "../firebase/config";
import { AuthContext } from "../context/AuthContext";

//api
import { isAFollowedByB, follow, unfollow } from "../api";

// components
import ProfilePostCard from "../components/ProfilePostCard";
import Header from "../components/Header";
import Footer from "../components/Footer";

// icons
import { MdVerified as VerifiedIcon } from "react-icons/md";
import { MdAddAPhoto as EditProfileIcon } from "react-icons/md";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import NotFound from "../components/NotFound";
import Loading from "../components/Loading";

const Profile = () => {
  const params = useParams();
  const { username } = params;
  const { user } = useContext(AuthContext);

  const [userProfile, setuserProfile] = useState(null);
  const profilePic = useRef();

  const [isLoading, setIsLoading] = useState(true);
  const [postIds, setPostIds] = useState([]);
  const [posts, setPosts] = useState([]);
  const [totalPosts, setTotalPosts] = useState(0); // State variable for total number of posts

  useEffect(() => {
    const getData = async () => {
      const userQuery = query(
        collection(firestore, "user"),
        where("userName", "==", username)
      );
      onSnapshot(userQuery, async (users) => {
        if (!users.empty) {
          setPostIds(users?.docs[0]?.data()?.posts);
          const profile = { id: users.docs[0].id, ...users?.docs[0]?.data() };
          const isFollowed =
            profile.id !== user.uid
              ? await isAFollowedByB(profile.id, user.uid)
              : false;
          setuserProfile({
            ...profile,
            isFollowed,
          });
          setIsLoading(false);
        }
        if (users.empty) {
          setuserProfile(null);
          setIsLoading(false);
        }
      });
    };
    getData();
  }, [username]);

  useEffect(() => {
    const readIds = async (ids) => {
      const reads = ids.map((id) => getDoc(doc(firestore, "posts", `${id}`)));
      const result = await Promise.all(reads);
      return result?.map((doc) => ({ id: doc?.id, ...doc.data() }));
    };
    if (postIds?.length > 0) {
      const getData = async () => {
        try {
          const response = await readIds(postIds);
          if (response) {
            setPosts(response?.reverse());
            // console.log(response);
          }
        } catch (error) {
          console.log(error);
        }
      };
      getData();
    }
  }, [postIds]);
  //Follow & Unfollow
  const followUser = async () => {
    await follow(
      userProfile.userId,
      user.uid,
      userProfile.followers + 1,
      user.following + 1
    );
  };
  const unfollowUser = async () => {
    await unfollow(
      userProfile.userId,
      user.uid,
      userProfile.followers - 1,
      user.following - 1
    );
  };

  return (
    <div>
      <Header />
      <div className="mt-16 min-h-screen">
        {userProfile && (
          <main className="bg-gray-100 bg-opacity-25">
            <div className="lg:max-w-5xl lg:mx-auto mb-8">
              <header className="flex flex-wrap items-center p-4 md:py-8">
                <div className="md:w-3/12 md:ml-16">
                  {/* profile image */}
                  <div className="relative group w-20 h-20 md:w-40 md:h-40 object-cover overflow-hidden rounded-full">
                    {userProfile?.id === user?.uid && (
                      <div className="absolute cursor-pointer opacity-0 group-hover:opacity-100 duration-75 transition-all top-0 left-0 h-full w-full bg-black/70 flex items-center justify-center text-2xl md:text-4xl text-white aspect-square">
                        <EditProfileIcon
                          htmlFor="profile-image"
                          onClick={() => profilePic.current.click()}
                        />
                        <input
                          type="file"
                          name="profile-image"
                          className="hidden h-full w-full"
                          id="profile-image"
                          ref={profilePic}
                          onChange={(e) => {
                            const file = e.target.files[0];
                            const storageRef = ref(
                              storage,
                              `users/${user?.uid}/profilePic.png`
                            );

                            const uploadTask = uploadBytesResumable(
                              storageRef,
                              file
                            );
                            uploadTask.on(
                              "state_changed",
                              (snap) => {
                                console.log(snap);
                              },
                              (err) => console.log(err),
                              () => {
                                getDownloadURL(uploadTask.snapshot.ref).then(
                                  async (downloadURL) => {
                                    console.log(
                                      "File available at",
                                      downloadURL
                                    );
                                    setDoc(
                                      doc(firestore, `user/${user?.uid}`),
                                      {
                                        photoURL: downloadURL,
                                      },
                                      { merge: true }
                                    );
                                  }
                                );
                              }
                            );
                          }}
                        />
                      </div>
                    )}
                    <LazyLoadImage
                      className="rounded-full h-full w-full border-2 border-pink-600 md:p-1 p-0.5"
                      src={
                        userProfile?.photoURL ||
                        "/images/temp-profile-photo-300x300.png"
                      }
                      alt={userProfile?.fullName}
                    />
                  </div>
                </div>
                {/* profile meta */}
                <div className="w-8/12 md:w-7/12 ml-4">
                  <div className="md:flex md:flex-wrap md:items-center mb-4">
                    <h2 className="text-3xl inline-block font-light md:mr-2 mb-2 sm:mb-0">
                      {userProfile?.userName}
                    </h2>
                    {/* badge */}
                    {userProfile?.isVerified && (
                      <span
                        className="inline-flex cursor-pointer text-blue-500 text-2xl mr-2"
                        title="A Verified User"
                      >
                        <VerifiedIcon />
                      </span>
                    )}
                    {/* follow button */}
                    {user?.uid !== userProfile?.id && (
                      <button
                        className={`${
                          userProfile?.isFollowed
                            ? "bg-gray-400"
                            : "bg-blue-500"
                        } px-4 py-1 
                  text-white font-semibold text-sm rounded block text-center 
                  sm:inline-block`}
                        onClick={() =>
                          userProfile?.isFollowed
                            ? unfollowUser()
                            : followUser()
                        }
                      >
                        {userProfile?.isFollowed ? "Following" : "Follow"}
                      </button>
                    )}
                  </div>
                  {/* post, following, followers list for medium screens */}
                  <ul className="hidden md:flex space-x-8 mb-4">
                    <li>
                      <span className="font-semibold">
                        {userProfile?.posts?.length || 0}{" "}
                      </span>
                      posts
                    </li>
                    <li>
                      <span className="font-semibold">
                        {userProfile?.followers?.length || 0}{" "}
                      </span>
                      followers
                    </li>
                    <li>
                      <span className="font-semibold">
                        {userProfile?.following?.length || 0}{" "}
                      </span>
                      following
                    </li>
                  </ul>
                  {/* user meta form medium screens */}
                  <div className="hidden md:block">
                    <h1 className="font-semibold">{userProfile?.fullName}</h1>
                    <p className="font-normal text-sm text-gray-600">
                      {userProfile?.categoryName}
                    </p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: `${userProfile?.biography
                          ?.replace("\n", "<br/>")
                          .replace("!\n", "<br/>")}`,
                      }}
                    ></p>
                    {userProfile?.link && (
                      <a
                        href={`https://${userProfile?.link}`}
                        target="_blank"
                        without
                        rel="noreferrer"
                        className="font-semibold text-blue-800"
                      >
                        {userProfile?.link}
                      </a>
                    )}
                  </div>
                </div>
                {/* user meta form small screens */}
                <div className="md:hidden text-sm my-2">
                  <h1 className="font-semibold">{userProfile?.fullName}</h1>
                  <span>{userProfile?.categoryName}</span>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: `${userProfile?.biography
                        ?.replace("\n", "<br/>")
                        ?.replace("!\n", "<br/>")}`,
                    }}
                  ></p>
                </div>
              </header>
              {/* posts */}
              <div className="px-px md:px-3">
                {/* user following for mobile only */}
                <ul
                  className="flex md:hidden justify-around space-x-8 border-t 
                text-center p-2 text-gray-600 leading-snug text-sm"
                >
                  <li>
                    <span className="font-semibold text-gray-800 block">
                      {userProfile?.posts?.length || 0}{" "}
                    </span>
                    posts
                  </li>
                  <li>
                    <span className="font-semibold text-gray-800 block">
                      {userProfile?.followers?.length || 0}{" "}
                    </span>
                    followers
                  </li>
                  <li>
                    <span className="font-semibold text-gray-800 block">
                      {userProfile?.following?.length || 0}{" "}
                    </span>
                    following
                  </li>
                </ul>
                {/* flexbox grid */}
                {posts?.length === 0 && (
                  <div className="flex items-center justify-center h-screen">
                    <div className="text-center">No posts yet</div>
                  </div>
                )}
                <motion.div
                  layout
                  className="grid grid-cols-3 md:gap-8 gap-1 md:p-2 p-1"
                >
                  {posts?.map((post, index) => (
                    <ProfilePostCard key={index} post={post} />
                  ))}
                </motion.div>
              </div>
            </div>
          </main>
        )}
        {isLoading && (
          <>
            <Loading />
          </>
        )}
        {!userProfile && (
          <div className="py-56 w-full flex items-center justify-center p-3">
            <NotFound />
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Profile;
