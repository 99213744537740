import {
  onSnapshot,
  collection,
  addDoc,
  updateDoc,
  doc,
  where,
  limit,
  getDoc,
  startAfter,
  query,
  orderBy,
  getDocs,
} from "firebase/firestore";
import { firestore } from "../firebase/config";
import { getUserDataFromuUserId } from "./user";

export const addComment = async (commentData, index) => {
  const commentsCollectionRef = collection(firestore, `comments`);
  await addDoc(commentsCollectionRef, commentData);

  try {
    const commentsCollectionRef = collection(
      firestore,
      `posts/${commentData?.postId}/comments`
    );
    await addDoc(commentsCollectionRef, {
      userID: commentData?.userId,
      comment: commentData?.content,
      likedBy: [],
      commentedAt: commentData?.createdAt,
    });
  } catch (error) {
    console.error("Error updating field:", error);
  }
};

/*
export const getCommentsByPostIt = async (postId, from, count) => {
    // const commentData = [];
    const commentCollectionRef = collection(firestore, 'comment');
    const commentQuery = from === null ? query(
        commentCollectionRef,
        where('postId', '==', postId),
        orderBy('createdAt', 'desc'),
        limit(count)
    ) : query(
        commentCollectionRef,
        where('postId', '==', postId),
        orderBy('createdAt', 'desc'),
        startAfter(from),
        limit(count)
    );
    try {
        const querySnapshot = await getDocs(commentQuery);
        const result = await querySnapshot.docs.reduce(async (prevPromise, acc) => {
            const prev = await prevPromise;
            const user = await getUserDataFromuUserId(acc.data().userId);
            return [...prev, { ...acc.data(), user }];
        }, []);
        return result;
    } catch (error) {
        console.error('Error fetching posts:', error);
        return [];
    }
}*/
export const getCommentsByPostId = async (postID, limitNum) => {
  try {
    const commentCollectionRef = collection(
      firestore,
      `posts/${postID}/comments`
    );
    const q = query(
      commentCollectionRef,
      orderBy("commentedAt"),
      limit(limitNum)
    );
    const data = await getDocs(q);
    return data.docs.map((comment) => ({
      id: comment.id,
      ...comment.data(),
    }));
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getCommentsByPostIt = async (postId, from, count) => {
  return new Promise((resolve, reject) => {
    const commentCollectionRef = collection(firestore, "comment");
    const commentQuery =
      from === null
        ? query(
            commentCollectionRef,
            where("postId", "==", postId),
            orderBy("createdAt", "desc"),
            limit(count)
          )
        : query(
            commentCollectionRef,
            where("postId", "==", postId),
            orderBy("createdAt", "desc"),
            startAfter(from),
            limit(count)
          );

    const unsubscribe = onSnapshot(
      commentQuery,
      async (querySnapshot) => {
        const result = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const data = doc.data();
            const user = await getUserDataFromuUserId(data.userId);
            return { ...data, user };
          })
        );
        resolve(result);
      },
      (error) => {
        console.error("Error fetching comments:", error);
        reject(error);
      }
    );
    // Return the unsubscribe function to allow unsubscribing from real-time updates
    return unsubscribe;
  });
};
//
