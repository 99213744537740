import React from 'react';

import { AiOutlineClose as CloseIcon } from "react-icons/ai";

const Dialog = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="relative bg-white rounded-lg">

                <div className='h-10 border-b border-gray-400'>
                    <button
                        className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                        onClick={onClose}
                    >
                        <CloseIcon size={25} />
                    </button>
                </div>
                <div className='p-4'>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Dialog;