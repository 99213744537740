import { useEffect, useState } from "react";
import Header from "../components/Header";
import ReelsVideoCard from "../components/ReelsPlayerCard";
import { reelsData } from "../constants/reelsData";
import { motion } from "framer-motion";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { firestore } from "../firebase/config";
import { getUserDataFromuUserId } from "../api";
const Reels = () => {
  const [reels, setReels] = useState([]);

  useEffect(() => {
    const getReels = async () => {
      const q = query(
        collection(firestore, "reels"),
        orderBy("createdAt", "desc")
      );
      const reels = await getDocs(q);

      const data = await Promise.all(
        reels?.docs.map(async (reel) => ({
          id: reel.id,
          user: await getUserDataFromuUserId(reel.data()?.userID),
          ...reel.data(),
        }))
      );
      setReels(data);
    };

    getReels();
  }, []);

  return (
    <motion.div>
      <Header />
      <div className="lg:max-w-4xl min-h-screen mt-14 md:p-3 lg:mx-auto mb-8">
        <div className="h-full w-full bg-transparent flex items-center justify-center">
          <div className="max-w-[400px] h-full md:h-auto w-full overflow-y-scroll snap-y aspect-[9/16] shadow-lg md:rounded">
            {reels?.map((video) => (
              <ReelsVideoCard key={video?.id} video={video} />
            ))}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Reels;
