import React from "react";

import Header from "../components/Header";
import Footer from "../components/Footer";

const About = () => {


    return (
        <>
            <Header />
            <div className="h-screen w-screen flex flex-wrap items-center justify-center p-3">
                Here is About Page
            </div >
            <Footer />
        </>
    );
};

export default About;
