import {
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  collection,
} from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { firestore } from "../firebase/config";

import Header from "../components/Header";

import HomePostCard from "../components/HomePostCard";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import Stories from "../components/Stories";
import Footer from "../components/Footer";
import { getUserDataFromuUserId } from "../api";

const Home = () => {
  const { user } = useContext(AuthContext);
  const [suggestUsers, setSuggestUsers] = useState();
  const [posts, setPosts] = useState([]);
  const [limitNum, setLimitNum] = useState(9);
  const [userProfile, setUserProfile] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (!user.emailVerified) navigate("/verify-email");

    const getData = async () => {
      const q = query(
        collection(firestore, "posts"),
        orderBy("createdAt", "desc"),
        limit(limitNum)
      );

      const posts = await getDocs(q);

      const data = await Promise.all(
        posts?.docs.map(async (post) => ({
          id: post.id,
          user: await getUserDataFromuUserId(post.data()?.userID),
          ...post.data(),
        }))
      );

      setPosts(data);
    };
    return getData();
  }, [limitNum]);

  useEffect(() => {
    const suggestUsers = async () => {
      const q = query(
        collection(firestore, "user"),
        orderBy("lastLogin", "desc")
      );
      onSnapshot(q, (snapshot) => {
        const users = snapshot.docs?.map((doc) => ({
          ...doc.data(),
          id: doc?.id,
        }));
        setSuggestUsers(users.filter((i) => i.id !== user.uid)?.slice(0, 8));
      });
    };
    return suggestUsers();
  }, []);

  useEffect(() => {
    const getData = async () => {
      const userData = await getDoc(doc(firestore, `/user/${user?.uid}`));
      setUserProfile(userData.data());
    };
    getData();
  }, []);

  return (
    <>
      {user.emailVerified ? (
        <>
          <Header />
          <div className="flex md:mt-14  max-w-4xl gap-2 mx-auto mb-8">
            <div className="w-full md:w-[70%]">
              <Stories />
              <div>
                {posts?.map((post) => (
                  <HomePostCard post={post} key={post?.id} />
                ))}
              </div>
              {posts?.length === 0 && (
                <div className="flex items-center justify-center h-screen">
                  <div className="text-center">No posts yet</div>
                </div>
              )}
              <div className="flex justify-center mt-8">
                <button
                  onClick={() => setLimitNum(limitNum + 9)}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Load More
                </button>
              </div>
            </div>
            <div className="hidden md:mt-7 md:block md:w-[30%] p-3">
              <div className="flex items-center justify-between w-full gap-2">
                <div>
                  <img
                    src={
                      userProfile?.photoURL ||
                      "/images/temp-profile-photo-300x300.png"
                    }
                    className="h-14 w-15 aspect-square object-cover rounded-full"
                    alt={userProfile?.fullName}
                  />
                </div>
                <div className="flex-grow">
                  <Link
                    to={`/${userProfile?.username}`}
                    className="text-sm font-semibold text-gray-800"
                  >
                    {userProfile?.username}
                  </Link>
                  <p className="text-gray-700 text-base">
                    {userProfile?.fullName}
                  </p>
                </div>
                <div className="text-sm font-bold text-blue-500">Switch</div>
              </div>
              <div>
                <div className="flex text-sm items-center my-2 justify-between">
                  <div className="text-gray-700  font-semibold">
                    Suggestions For You
                  </div>
                  <button className="text-slate-800 font-bold">See All</button>
                </div>
              </div>
              <div>
                {suggestUsers?.slice(1, 10).map((item, index) => (
                  <div
                    className="flex items-center  justify-between my-2"
                    key={index}
                  >
                    <div className="flex gap-2 items-center">
                      <Link to={`/${item?.userName}`}>
                        <img
                          src={
                            item?.photoURL ||
                            "/images/temp-profile-photo-300x300.png"
                          }
                          className="h-7 w-7 aspect-square object-cover rounded-full"
                          alt={item?.userName}
                        />
                      </Link>
                      <div>
                        <Link
                          to={`/${item?.userName}`}
                          className="text-sm font-semibold text-gray-800"
                        >
                          {item?.userName}
                        </Link>
                        <p className="text-[10px] text-gray-500">
                          {item.fullName}
                        </p>
                      </div>
                    </div>
                    <Link
                      to={`/${item?.userName}`}
                      className="text-xs font-bold text-blue-500"
                    >
                      Follow
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <Footer />
        </>
      ) : (
        <>ss</>
      )}
    </>
  );
};

export default Home;
