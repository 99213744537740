export const ReelIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 122.14 122.88"
    xmlSpace="preserve"
    // size={20}
    width="17px"
    height="17px"
    {...props}
  >
    <path d="M35.14 0H87c9.65 0 18.43 3.96 24.8 10.32 6.38 6.37 10.34 15.16 10.34 24.82v52.61c0 9.64-3.96 18.42-10.32 24.79l-.02.02c-6.38 6.37-15.16 10.32-24.79 10.32H35.14c-9.66 0-18.45-3.96-24.82-10.32l-.24-.27C3.86 105.95 0 97.27 0 87.74v-52.6c0-9.67 3.95-18.45 10.32-24.82S25.47 0 35.14 0zm56.37 31.02l.07.11h21.6c-.87-5.68-3.58-10.78-7.48-14.69-4.8-4.8-11.42-7.78-18.7-7.78h-8.87l13.38 22.36zm-9.99.11L68.07 8.66h-29.5l13.61 22.47h29.34zm-39.41 0L28.95 9.39a26.446 26.446 0 00-12.51 7.05c-3.9 3.9-6.6 9.01-7.48 14.69h33.15zm71.37 8.66H8.66v47.96c0 7.17 2.89 13.7 7.56 18.48l.22.21c4.8 4.8 11.43 7.79 18.7 7.79H87c7.28 0 13.9-2.98 18.69-7.77l.02-.02c4.79-4.79 7.77-11.41 7.77-18.69V39.79zM50.95 54.95L77.78 72.4c.43.28.82.64 1.13 1.08a3.9 3.9 0 01-1 5.42L51.19 94.67c-.67.55-1.53.88-2.48.88a3.91 3.91 0 01-3.91-3.91V58.15h.02a3.902 3.902 0 016.13-3.2z" />
  </svg>
);

export const ReelFillIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
    // size={20}
    width="20px"
    height="20px"
    {...props}
  >
    <path d="M13.34 4.13L20.26 16H4v-1C4 9.48 8.05 4.92 13.34 4.13zM33.26 16L22.57 16 15.57 4 26.26 4zM46 15v1H35.57l-7-12H35C41.08 4 46 8.92 46 15zM4 18v17c0 6.08 4.92 11 11 11h20c6.08 0 11-4.92 11-11V18H4zM31 32.19l-7.99 4.54C21.68 37.49 20 36.55 20 35.04v-9.08c0-1.51 1.68-2.45 3.01-1.69L31 28.81C32.33 29.56 32.33 31.44 31 32.19z" />
  </svg>
);
