import { useEffect, useState, useContext, useRef } from 'react';
import { Link } from "react-router-dom";

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

// icons
import { CgHeart as HeartIcon } from "react-icons/cg";
import { FaHeart as HeartFillIcon } from "react-icons/fa";
import { RiChat3Line as CommentIcon } from "react-icons/ri";
import { FiSend as SendIcon } from "react-icons/fi";
import { BsBookmark as TagIcon } from "react-icons/bs";
import { BsBookmarkFill as TagFillIcon } from "react-icons/bs";
import { AiOutlineClose as CloseIcon } from "react-icons/ai";
import { AiOutlineSmile as SmileIcon } from "react-icons/ai";

import {
    serverTimestamp,
} from "firebase/firestore";
//context
import { AuthContext } from "../../context/AuthContext";

//api
import { getCommentsByPostIt, addComment } from "../../api";

//util
import { formatTimeDifference } from '../../util';

const PostDetailView = ({ isOpen, onClose, post, liked, likedPost, saved }) => {

    const { user } = useContext(AuthContext);

    const [comments, setComments] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [commentInput, setCommentInput] = useState('');

    useEffect(async () => {
        if (isOpen && post.postId !== null) {
            const commentsData = await getCommentsByPostIt(post.id, null, 20);
            setComments(commentsData);
            setIsLoading(false);
        }
    }, [post.id, isOpen]);


    const commentSubmit = async (e) => {
        e.preventDefault();
        const commentData = {
            userId: user?.uid,
            postId: post.id,
            content: commentInput.trim(),
            createdAt: serverTimestamp(),
        };
        await addComment(commentData, post.commentCount + 1);
        setComments(prevState => [commentData, ...prevState]);
        setCommentInput("");
    };

    //Ref
    const commentRef = useRef(null);

    if (!isOpen) return null;
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <button
                className="absolute top-16 right-2 text-black hover:text-gray-700"
                onClick={onClose}
            >
                <CloseIcon size={25} />
            </button>

            <div className="relative w-4/5 h-3/4 bg-white rounded-lg">
                <div className='flex h-full'>
                    <div className='flex flex-1 justify-around bg-gray-900'>
                        <img className="object-contain"
                            src={post.media[0]} />
                    </div>
                    <div className='flex flex-col relative w-80'>
                        <div className='h-12 py-2 px-4 border-b border-gray-200'>
                            <div className='flex'>
                                <Link to={`/${post?.user?.userName}`}>
                                    <img
                                        src={
                                            post?.user?.photoURL ||
                                            "/images/temp-profile-photo-300x300.png"
                                        }
                                        className="rounded-full h-8 w-8 object-cover"
                                        alt={post?.user?.userName}
                                    />
                                </Link>
                                <p className='ml-3 mt-1 font-semibold'>{post?.user?.userName}</p>
                            </div>
                        </div>
                        <div className='p-4 flex-1 overflow-scroll'>
                            {isLoading ? <Skeleton count={5} /> : <>  {comments.map((comment, index) => (
                                <div className='flex mb-1' key={index}>
                                    <Link to={`/${comment?.user?.userName}`}>
                                        <img
                                            src={
                                                comment?.user?.photoURL ||
                                                "/images/temp-profile-photo-300x300.png"
                                            }
                                            className="rounded-full h-8 w-8 object-cover"
                                            alt={comment?.user?.userName}
                                        />
                                    </Link>
                                    <div className='flex-1 ml-3'>
                                        <p>
                                            <span className='mt-1 font-semibold'>{comment?.user?.userName}</span> &nbsp;&nbsp;
                                            <span>{comment.content}</span>
                                        </p>
                                        <p className='text-xs font-semibold text-gray-400'>{formatTimeDifference(comment.createdAt)}</p>
                                    </div>
                                </div>
                            ))}</>}
                        </div>
                        <div className='bottom-0 w-full'>
                            <div className='p-4 border-t border-gray-200'>
                                <div className="flex text-2xl w-full">
                                    <div className="flex w-full text-slate-900 gap-2">
                                        {liked ? (
                                            <button onClick={() => likedPost(false)}>
                                                <HeartFillIcon color="#ff2828" />
                                            </button>
                                        ) : (
                                            <button onClick={() => likedPost(true)}>
                                                <HeartIcon size={25} />
                                            </button>
                                        )}
                                        <button onClick={() => commentRef.current && commentRef.current.focus()}>
                                            <CommentIcon />
                                        </button>
                                        <button>
                                            <SendIcon />
                                        </button>
                                    </div>
                                    <button>
                                        {saved ? <TagFillIcon /> : <TagIcon />}
                                    </button>
                                </div>
                                <p className='mt-2 text-sm font-semibold'>{post.likeCount} likes</p>
                                <p className='text-xs font-semibold text-gray-400'>{formatTimeDifference(post.createdAt, false)}</p>
                            </div>
                            <div className=" sm:block sm:border-t-[1px] text-slate-900 p-3 border-slate-500/30">
                                <form onSubmit={commentSubmit}>
                                    <div className="flex items-center gap-3">
                                        <SmileIcon size={24} />
                                        <input
                                            type="text" ref={commentRef}
                                            className="w-full text-sm outline-none font-light"
                                            placeholder="Add a comment..."
                                            value={commentInput}
                                            onChange={(e) => setCommentInput(e.target.value)}
                                        />
                                        <button
                                            type="submit"
                                            disabled={commentInput.length <= 0}
                                            className="text-blue-500 font-semibold text-sm"
                                        >
                                            Post
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div >
    );
};

export default PostDetailView;