import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { sendVerificationEmail } from "../firebase/config";
import { AuthContext } from "../context/AuthContext";

const EmailVerification = () => {
  const navigate = useNavigate();

  const { user, logout } = useContext(AuthContext);
  if (user?.emailVerified) navigate("/");

  return (
    <>
      <div className="h-screen w-screen flex flex-wrap items-center justify-center p-3">
        <div className="flex items-center">
          <div className="flex flex-col flex-shrink-0 w-[350px]">
            <div className="flex flex-col items-center justify-center rounded w-full border-[1px] border-gray-300 bg-white p-6">
              <div className="w-full">
                <img
                  src="/images/logo-full.png"
                  className="h-14 mt-4 mx-auto my-3"
                  alt="Myydoctor"
                />
              </div>
              <div className="w-full px-5 text-sm text-center">
                <p>
                  Please check your email for a verification link. &nbsp; Click
                  on the verification link to complete the email verification
                  process.
                </p>

                <div className="text-sm mt-3">
                  <p>
                    After email verification, simply click the button below to
                    visit the home page
                  </p>
                  <button
                    onClick={() => navigate("/")}
                    className="text-blue-500 font-semibold"
                  >
                    Go to home page
                  </button>
                </div>
              </div>
            </div>

            <div className="flex flex-col items-center justify-center rounded w-full border-[1px] border-gray-300 mt-4 bg-white p-6">
              <div className="text-sm">
                <button
                  onClick={sendVerificationEmail}
                  className="text-blue-500 font-semibold"
                >
                  Resend Verification Email
                </button>
              </div>
              <div className="text-sm">
                <button
                  onClick={() => {
                    logout();
                    navigate("/");
                  }}
                  className="text-blue-500 font-semibold"
                >
                  Log Out
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default EmailVerification;
