export const FakeUsers = [
  {
    photoURL:
      "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8&w=100&q=80",
    username: "deadofwrite",
    name: "Priscilla Knowles",
  },
  {
    photoURL:
      "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8cmFuZG9tJTIwcGVyc29ufGVufDB8fDB8fA%3D%3D&w=100",
    username: "weworewhat",
    name: "Colin Robinson",
  },
  {
    photoURL:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8&w=1000&q=80",
    username: "babynative",
    name: "Misty Perry",
  },
  {
    photoURL:
      "https://www.citrix.com/blogs/wp-content/upload/2018/03/slack_compressed-e1521621363404-360x360.jpg",
    username: "sincerelyjules",
    name: "Misty Perry",
  },
  {
    photoURL: "http://www.venmond.com/demo/vendroid/img/avatar/big.jpg",
    username: "wherearetheavocados",
    name: "Una Pena",
  },
  {
    photoURL:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXs_iIewEiaZ3tXb6n6VgaUIONS0B0HjwsqcvA3-EnnaNm0BwX216u2dZl2QTHnP7VOIU&usqp=CAU",
    username: "loversland",
    name: "Audrey Dittman",
  },
  {
    photoURL:
      "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dXNlcnxlbnwwfHwwfHw%3D&w=100&q=80",
    username: "inkandfable",
    name: "Ursa Medina",
  },
  {
    photoURL: "https://i.imgur.com/JFHjdNr.jpg",
    username: "nitch",
    name: "Herman Schmidt",
  },
  {
    photoURL:
      "https://htmlstream.com/preview/unify-v2.6/assets/img-temp/400x450/img5.jpg",
    username: "iamwellandgood",
    name: "Prudence Flowers",
  },
];
