import { Timestamp } from 'firebase/firestore';

export const formatTimeDifference = (timestampData, useShortForm = true) => {
    const timestamp = Timestamp.fromMillis(timestampData.seconds * 1000 + Math.floor(timestampData.nanoseconds / 1000000));
    const firestoreDate = timestamp.toDate();

    const currentDate = Date.now();
    const timeDifference = currentDate - firestoreDate.getTime();

    const minutes = Math.floor(timeDifference / (1000 * 60));
    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    if (days >= 2) {
        return useShortForm ? `${days}d` : `${days} days ago`;
    } else if (days === 1) {
        return useShortForm ? `1d` : `1 day ago`;
    } else if (hours >= 1) {
        return useShortForm ? `${hours}h` : hours > 1 ? `${hours} hours ago` : `${hours} hour ago`;
    } else {
        return useShortForm ? `${minutes}min` : minutes > 1 ? `${minutes} minutes ago` : `${minutes} minute ago`;
    }
}