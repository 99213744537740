import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  setDoc,
  where,
  orderBy,
  limit,
  getDocs,
} from "firebase/firestore";
import { firestore, storage } from "../firebase/config";

import { getUserDataFromuUserId } from "../api";

import Popup from "./wrapper";

export default function LikesList({ isOpen, onClose, postId }) {
  const [isLoading, setIsLoading] = useState(true);
  const [likes, setLikes] = useState([]);

  useEffect(() => {
    const getLikes = async () => {
      const likesData = [];
      const likeQuery = query(
        collection(firestore, "like"),
        where("postId", "==", postId),
        limit(30)
      );

      onSnapshot(
        likeQuery,
        async (likes) => {
          const promises = likes.docs.map(async (doc) => {
            const temp = doc.data();
            const userData = await getUserDataFromuUserId(temp.userId);
            return {
              ...temp,
              user: userData,
            };
          });

          const likesArray = await Promise.all(promises);
          setLikes(likesArray);
          setIsLoading(false);
        },
        (error) => {
          console.error("Error fetching likes:", error);
        }
      );
    };

    if (isOpen) {
      getLikes();
    }
  }, [postId]);

  return (
    <>
      <Popup isOpen={isOpen} onClose={onClose}>
        <div className="w-64">
          {isLoading ? (
            <Skeleton count={5} />
          ) : (
            <div>
              {likes.map((like, index) => (
                <div className="flex justify-between mb-1" key={index}>
                  <div className="flex justify-between">
                    <img
                      src={
                        like?.user?.photoURL ||
                        "/images/temp-profile-photo-300x300.png"
                      }
                      className="h-10 w-10 mr-4 aspect-square object-cover rounded-full border-gray-400 border"
                      alt={like?.user?.username}
                    />
                    <div>
                      <p className="text-sm font-semibold">
                        {like?.user?.userName}
                      </p>
                      <p className="text-sm">{like?.user?.fullName}</p>
                    </div>
                  </div>
                  <button className="my-1 rounded-lg bg-blue-500 text-white px-4 py-1">
                    Follow
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      </Popup>
    </>
  );
}
