import {
  onSnapshot,
  addDoc,
  collection,
  doc,
  where,
  getDocs,
  deleteDoc,
  query,
  serverTimestamp,
  updateDoc,
  setDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { firestore } from "../firebase/config";

export const isPostLikedByUser = (postId, userId) => {
  return new Promise((resolve, reject) => {
    const likeQuery = query(
      collection(firestore, "like"),
      where("postId", "==", postId),
      where("userId", "==", userId)
    );

    const unsubscribe = onSnapshot(
      likeQuery,
      (snapshot) => {
        const isLiked = snapshot.docs.length > 0;
        resolve(isLiked);
      },
      (error) => {
        console.error("Error fetching likes:", error);
        reject(error);
      }
    );

    // Return the unsubscribe function to allow unsubscribing from real-time updates
    return unsubscribe;
  });
};

export const setPostLiked = async (postId, userId) => {
  try {
    const postDocRef = doc(firestore, `posts/${postId}`);
    await setDoc(
      postDocRef,
      {
        likedBy: arrayUnion(userId),
      },
      {
        merge: true,
      }
    );
  } catch (error) {
    console.log(error);
  }
};

export const unsetPostLiked = async (postId, userId) => {
  try {
    const postDocRef = doc(firestore, `posts/${postId}`);
    await setDoc(
      postDocRef,
      {
        likedBy: arrayRemove(userId),
      },
      {
        merge: true,
      }
    );
  } catch (error) {
    console.log(error);
  }
};
