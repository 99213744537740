import {
  collection,
  onSnapshot,
  query,
  serverTimestamp,
  where,
  getDocs,
  doc,
  addDoc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { firestore } from "../firebase/config";

const usersRef = collection(firestore, "user");

export const getUserDataFromuUserId = async (userId) => {
  const userQuery = query(usersRef, where("userId", "==", userId));
  const userSnapshot = await getDocs(userQuery);
  if (userSnapshot.size > 0) {
    const userDoc = userSnapshot.docs[0];
    return userDoc.data();
  } else {
    console.log("User does not exist");
    return null;
  }
};

export const isAFollowedByB = (userA, userB) => {
  return new Promise((resolve, reject) => {
    const followQuery = query(
      collection(firestore, "follow"),
      where("followee", "==", userA),
      where("follower", "==", userB)
    );

    const unsubscribe = onSnapshot(
      followQuery,
      (snapshot) => {
        const isFollowed = snapshot.docs.length > 0;
        resolve(isFollowed);
      },
      (error) => {
        console.error("Error fetching likes:", error);
        reject(error);
      }
    );

    // Return the unsubscribe function to allow unsubscribing from real-time updates
    return unsubscribe;
  });
};

export const follow = async (userA, userB, indexA, indexB) => {
  const data = {
    createdAt: serverTimestamp(),
    followee: userA,
    follower: userB,
  };
  try {
    await addDoc(collection(firestore, "follow"), data);
  } catch (error) {
    console.error("Error adding document:", error);
  }

  try {
    const docRef = doc(firestore, "user", userA);
    await updateDoc(docRef, {
      followers: indexA,
    });
  } catch (error) {
    console.error("Error updating field:", error);
  }

  try {
    const docRef = doc(firestore, "user", userB);
    await updateDoc(docRef, {
      following: indexB,
    });
  } catch (error) {
    console.error("Error updating field:", error);
  }
};

export const unfollow = async (userA, userB, indexA, indexB) => {
  const likeQuery = query(
    collection(firestore, "follow"),
    where("followee", "==", userA),
    where("follower", "==", userB)
  );
  getDocs(likeQuery)
    .then((querySnapshot) => {
      if (!querySnapshot.empty) {
        const documentSnapshot = querySnapshot.docs[0];
        return deleteDoc(documentSnapshot.ref);
      } else {
        throw new Error("Document not found.");
      }
    })
    .then(() => {
      console.log("Documents deleted successfully.");
    })
    .catch((error) => {
      console.error("Error deleting documents:", error);
    });

  try {
    const docRef = doc(firestore, "user", userA);
    await updateDoc(docRef, {
      followers: indexA,
    });
  } catch (error) {
    console.error("Error updating field:", error);
  }

  try {
    const docRef = doc(firestore, "user", userB);
    await updateDoc(docRef, {
      following: indexB,
    });
  } catch (error) {
    console.error("Error updating field:", error);
  }
};
