import React from "react";

const Footer = () => {
  const date = new Date();
  return (
    <footer className="text-center p-3 font-thin max-w-4xl md:text-sm text-xs mx-auto text-gray-700 w-full">
      <div className="px-3">
        <div className="w-full ">
          <div className="flex items-center flex-wrap justify-between w-full mb-3">
            <div className="">
              <a href="/about">
                <div className="">About</div>
              </a>
            </div>
            <div className="">
              <a href="/blog"              >
                <div className="">Blog</div>
              </a>
            </div>
            <div className="">
              <a href="/jobs">
                <div className="">Jobs</div>
              </a>
            </div>
            <div className="">
              <a href="/help">
                <div className="">Help</div>
              </a>
            </div>
            <div className="">
              <a href="/api">
                <div className="">API</div>
              </a>
            </div>
            <div className="">
              <a href="/privacy">
                <div className="">Privacy</div>
              </a>
            </div>
            <div className="">
              <a href="/terms">
                <div className="">Terms</div>
              </a>
            </div>
            <div className="">
              <a href="/profiles">
                <div className="">Top Accounts</div>
              </a>
            </div>
            <div className="">
              <a href="/locations">
                <div className="">Locations</div>
              </a>
            </div>
            <div className="">
              <a href="/lite">
                <div className="">MyyDoctor Lite</div>
              </a>
            </div>
          </div>
        </div>
        <div className="text-sm">
          <div className="">
            <div className="">
              <span className="font-semibold">&copy; {date.getFullYear()}</span>{" "}
              MyyDoctor{" "}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
